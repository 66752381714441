import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Box } from "theme-ui";
import Layout from "../components/layout";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout title="404" mdxType="Layout">
      <Box sx={{
        textAlign: "center"
      }} mdxType="Box">
        <h1>{`NOT FOUND`}</h1>
        <p>{`You just hit a route that doesn't exist... the sadness.`}</p>
      </Box>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      